import time from '@nrk/yr-time';
import settings from '../../app/settings';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ITide, ITideEntry } from '../../model/coast';
import { Card, CardHeader, CardLinkFooter } from '../Card/Card';
import { Heading } from '../Heading/Heading';
import { RelativeTime } from '../RelativeTime/RelativeTime';
import './TideCard.scss';
import { TideCard__Empty } from './TideCard__Empty';
import { TideCard__InfoPanel } from './TideCard__InfoPanel';
import { TideCard__Table } from './TideCard__Table';

interface IProps {
  locationName: string;
  tide?: ITide;
  isInitialLoading: boolean;
  isError: boolean;
}

export const TideCard = (props: IProps) => {
  const { locationName, tide, isInitialLoading, isError } = props;

  const translate = useTranslate();
  const localeCode = useLocaleCode();

  if (isError) {
    return (
      <Card
        type="error"
        footer={
          <CardLinkFooter
            text={translate('tideCard/footerText')}
            link={{
              url: settings.external.tide[localeCode].url,
              text: settings.external.tide[localeCode].displayUrl
            }}
          />
        }
        errorMessage={translate('tideCard/error/message')}
      ></Card>
    );
  }

  if (isInitialLoading || tide == null) {
    return <TideCard__Empty />;
  }

  if (tide.status.code === 'Unavailable') {
    return (
      <Card
        type="error"
        footer={
          <CardLinkFooter
            text={translate('tideCard/footerText')}
            link={{
              url: settings.external.tide[localeCode].url,
              text: settings.external.tide[localeCode].displayUrl
            }}
          />
        }
        errorMessage={tide.status.message ?? translate('tideCard/error/message')}
      ></Card>
    );
  }

  const tidesByUniqueDays = getTidesByUniqueDays(tide.tides);

  const tideTables = tidesByUniqueDays.map((tide, index) => {
    const relativeDayLabel = <RelativeTime time={tide[0].time} type="relative-date-short" transform="sentence-case" />;

    return (
      <div className="tide-card__table-container" key={index}>
        <Heading level="h3" size="4">
          {relativeDayLabel}
        </Heading>

        <TideCard__Table tides={tide} relativeDayLabel={relativeDayLabel} visuallyHiddenHeaders={index > 0} />
      </div>
    );
  });

  return (
    <Card
      testId="tide-card"
      footer={
        <CardLinkFooter
          text={translate('tideCard/footerText')}
          link={{
            url: tide.url,
            text: tide.displayUrl
          }}
        />
      }
    >
      <CardHeader
        title={locationName}
        infoButton={{
          type: 'button',
          renderInfoPanel: (openedById: string, onClose: () => void) => {
            return <TideCard__InfoPanel tide={tide} openedById={openedById} onClose={onClose} />;
          }
        }}
      />
      {tideTables}
    </Card>
  );
};

// This returns an array of ITideEntry[], so that we have one array with data for each unique day
function getTidesByUniqueDays(tides: ITideEntry[]) {
  const uniqueDays = getUniqueDayIndexesFromTides(tides);

  const tidesByUniqueDays: ITideEntry[][] = uniqueDays.map(day => {
    const currentDayTideData = tides.filter(tide => {
      const currentTideDay = time.create(tide.time).day();
      return day === currentTideDay;
    });

    return currentDayTideData;
  });

  return tidesByUniqueDays;
}

function getUniqueDayIndexesFromTides(tides: ITideEntry[]) {
  const uniqueDays = tides
    .map(tide => {
      const dayIndex = time.create(tide.time).day();
      return dayIndex;
    })
    .filter((currentDayIndex, index, allDayIndexes) => allDayIndexes.indexOf(currentDayIndex) === index);

  return uniqueDays;
}
